.statisticsContainer {
  background: transparent
    linear-gradient(110deg, rgba(62, 1, 84, 1) 0%, rgba(185, 4, 252, 1) 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  padding: 2rem 0rem;
  justify-content: space-around;
  align-items: center;
}
.counterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.counterContainer h3 {
  color: #ffffff;
  font: normal normal bold 1.8rem Noto-Sans-Bold;
  letter-spacing: 0px;
}
.counter {
  color: #ffffff;
  font: normal normal bold 2.5rem Noto-Sans-Bold;
  letter-spacing: 0px;
}
