.productCardWrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.proteinCardWrapper {
  height: 50vh;
  width: 50vh;
  border-radius: 5vh;
  background: linear-gradient(180deg, #192036 0%, #384674 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.proteinJarImg {
  height: 80%;
  position: relative;
  top: -50px;
}

.priceTagImg {
  position: absolute;
  top: 10%;
  right: 10%;
  height: 25%;
}

.cardBackground {
  width: 100%;
  position: absolute;
  bottom: -60%;
  opacity: 0.5;
}

.topCardDetails {
  position: relative;
  top: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.orderButton {
  background: linear-gradient(180deg, #A3744B 0%, #F9BA83 100%);
  padding: 12px 24px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 2px solid #A3744B;
}

.orderButton:hover {
  background: white;
  transition: 1000ms;
  cursor: pointer;

}

.orderButton:hover h4 {
  color: #A3744B;
}

.detailsForm {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow-y: hidden;
}

.detailsForm .header {
  display: flex;
  flex-direction: row;
}

.detailsForm .header img {
  position: absolute;
  top: 20px;
  right: 10px;
}

.detailsForm h1 {
  font-size: 42px;
}

.detailsForm form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detailsForm input {
  background: #d9d9d9;
  font-size: 18px;
  font-family: "Avenir Regular";
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 5px;
  padding: 0 2rem;
}

.formSplitter {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.detailsForm .submitButton {
  width: 20%;
  align-self: center;
  padding: 6px 12px;
  background: linear-gradient(90deg, #F5F2ED 0%, #A2734A 100%);
  font-family: "Avenir Regular";
}

.blueHalfCircle {
  position: absolute;
  border-radius: 882px;
  background: linear-gradient(90deg, #181F35 0%, #384674 100%);
  width: 40rem;
  height: 40rem;
  bottom: -20rem;
  left: -20rem;
  z-index: -1;
}

/* For mobile devices */
@media screen and (max-width: 480px) {
  .productCardWrapper {
    flex-direction: column;
    gap: 80px;
    margin: 30px 0;
  }

  .proteinCardWrapper {
    width: 80vw;
    height: 80vw;
  }


  .detailsForm .header img {
    position: absolute;
    top: 0px;
    right: 5px;
    scale: 0.4;
  }

  .detailsForm h1 {
    font-size: 24px;
  }


  .detailsForm .submitButton {
    width: 30%;
  }

  .blueHalfCircle {
    border-radius: 882px;
    width: 100%;
    height: 100%;
    bottom: -50%;
    left: 0;
    z-index: -1;
  }

}