.navOuterContainer {
  width: 100%;
  height: 13.5vh;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgb(255, 255, 255, 0.84);
  position: absolute;
  transition: position 0.3s ease;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
}

.navLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  height: 100%;
  padding-left: 7rem;
}

.navLogoContainer img {
  object-fit: contain;
  height: 72px;
}

.navItemContainer {
  width: 50%;
}

.navItemContainer nav {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.navItemContainer ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.navItemContainer li {
  list-style: none;
}

.navDownload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.navDownload h4 {
  font-family: "Avenir Regular";
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: white;
  background-color: #181F35;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  padding: 0rem 1rem;
}

.navPhoneBar {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 18%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.barContainer {
  cursor: pointer;
}

.barContainer span {
  width: 2.6rem;
  height: 0.3rem;
  background-color: black;
  border-radius: 2px;
}

.barContainer {
  height: 2rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sideNavContainer {
  transition: all 0.5s ease;
  z-index: 10;
  border-radius: 48px 0px 0px 48px;
  background: linear-gradient(180deg, #353535 0%, #000 100%);
  padding: 0rem 0rem;
  position: absolute;
  top: 3vh;
  right: 0;
  width: 0px;
  max-width: 29rem;
  overflow-x: hidden;
}

.sideNavContainer.active {
  padding: 3rem 3rem;
  width: 60%;
}

.sideNavContainer li {
  list-style: none;
  padding-bottom: 3rem;
  text-align: center;
  text-wrap: nowrap;
}

.rightShiftContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.rightShiftContainer img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.dummySpace {
  width: 100%;
  height: 13vh;
  transition: all 0.1s ease;
}

.sideBarItems {
  font-size: 2rem;
  line-height: 3.2rem;
}

/* ---------------------------------------------------------for width upto 768px */
@media (max-width: 768px) {
  .navItemContainer {
    display: none;
  }

  .navLogoContainer {
    width: 100%;
    justify-content: center;
  }

  .navDownload {
    display: none;
  }

  .navPhoneBar {
    display: flex;
  }
}

/* ------------------------------------------------------------for width upto 320px */
@media (max-width: 400px) {

  .navLogoContainer {
    padding-left: 0;
  }

  .navLogoContainer img {
    height: 72px;
  }

  .navPhoneBar {
    width: 20%;
  }

  .sideBarItems {
    font-size: 3rem;
    line-height: 4.2rem;
  }

  .sideNavContainer {
    max-width: 100%;
  }

  .sideNavContainer.active {
    width: 70%;
  }

  .rightShiftContainer img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
  }
}

/* ------------------------------for width upto 300px */
@media (max-width: 300px) {
  .sideNavContainer.active {
    width: 90%;
  }
}