.maskLayer {
  background: linear-gradient(
    0deg,
    rgba(86, 0, 149, 0.72) 6.68%,
    rgba(36, 36, 36, 0.56) 42.33%
  );
  padding: 13rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.communityContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.gradientText {
  position: relative;
  font-family: "Varien";
  font-weight: 400;
  font-size: 4rem;
  line-height: 3.7rem;
  text-align: center;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.27);
}
.gradientText::before {
  content: "Join the Biggest Fitness Community";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #efabff, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.maskLayer h3 {
  font-family: "Avenir Regular";
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.2rem;
  text-align: center;
  color: white;
  margin-top: 1rem;
}
.mediaContainer {
  margin-top: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.mediaContainer a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0);
  transition: box-shadow 0.15s;
}
.mediaContainer img {
  width: 5.2rem;
  height: 5.2rem;
  object-fit: contain;
}
.mediaContainer a:hover {
  box-shadow: 8px 8px 20px rgba(255, 255, 255, 0.3);
  opacity: 0.7;
}
/* -------------------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .maskLayer {
    padding: 13rem 2rem;
    align-items: flex-start;
  }
  .gradientText {
    font-size: 6rem;
    line-height: 7rem;
    text-align: left;
    width: 80%;
  }
  .maskLayer h3 {
    font-size: 2.6rem;
    line-height: 3.2rem;
    text-align: left;
    margin-top: 2rem;
    width: 80%;
  }
}
/* -------------------------------------------for width upto 400px */
@media screen and (max-width: 400px) {
  .maskLayer {
    padding: 13rem 2rem;
    align-items: flex-start;
  }
  .gradientText {
    font-size: 5rem;
    line-height: 6rem;
    text-align: left;
    width: 100%;
  }
  .maskLayer h3 {
    font-size: 2.6rem;
    line-height: 3.2rem;
    text-align: left;
    margin-top: 2rem;
    width: 100%;
  }
}
