.footerLogoContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLogoContainer img {
  object-fit: contain;
  height: 6rem;
}

.footer-store-container {
  position: relative;
  background: #ebeaea;
  box-shadow: 3px 4px 2px 6px rgba(0, 0, 0, 0.25);
  padding: 6rem 0 2.2rem 0;
}

.footerOption {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerOption ul {
  width: 52%;
  display: flex;
  justify-content: space-between;
}

.footerOption li {
  padding-top: 2rem;
  font-size: 24px;
  font-family: "Avenir Regular";
  font-weight: 900;
  list-style: none;
}

.footerOption a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.footerOption a:hover {
  color: #7404b9;
}

.reserve-right-container {
  text-align: center;
  padding: 5rem 0 5rem 0;
  color: #000;
}

.reserve-right-text {
  font-size: 20px;
  color: #5e6282;
}

.app-download {
  display: grid;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 140px;
}

.download-logo {
  height: 3rem;
}

.social-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-logo {
  height: 4.1rem;
  width: 4.1rem;
  object-fit: contain;
}

.discover-app-text {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 20px;
}

@media screen and (max-width: 980px) {
  .footerOption {
    width: 56%;
  }

  .footerOption ul {
    margin-top: 3rem;
    flex-direction: column;
  }

  .app-download {
    top: 220px;
    right: 60px;
  }
}

@media (max-width: 700px) {
  .footerOption li {
    font-size: 18px;
  }

  .reserve-right-text {
    font-size: 16px;
  }

  .app-download {
    right: 20px;
  }
}

@media screen and (max-width: 480px) {

  .footerLogoContainer img {
    height: 12rem;
  }

  .footerOption a {
    font-size: 1rem;
  }

  .footerOption ul {
    width: 70%;
  }

  .footerOption li {
    padding: 0;
  }

  .download-logo {
    height: 2.2rem;
  }

  .social-logo {
    height: 4.2rem;
  }

  .discover-app-text {
    font-size: 12px;
  }

  .app-download {
    top: 200px;
  }
}

@media screen and (max-width: 380px) {
  .footerOption li {
    line-height: 2.8rem;
  }

  .footerOption a {
    font-size: 1.2rem;
  }

  .app-download {
    top: 180px;
  }

  .reserve-right-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 300px) {
  .app-download {
    top: 190px;
  }

  .download-logo {
    height: 1.9rem;
  }

  .social-logo {
    height: 3.8rem;
  }
}