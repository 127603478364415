.carouselContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 95vh;
  background: radial-gradient(
    90.81% 69.4% at 90.28% 82.68%,
    #7404b9 0%,
    #fff 100%
  );
  overflow: hidden;
}
.adContent {
  height: 100%;
  width: 50%;
  padding-left: 4rem;
  padding-top: 5.5rem;
}
.slideContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.slideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 900px;
}
.adContent h2 {
  color: #df6951;
  font-family: "Poppins Medium";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.priceContainer h1 {
  color: #131313;
  font-family: "Volkhov Regular";
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 6.2rem;
  letter-spacing: -1px;
}
.descriptionLine {
  margin-top: 3.5rem;
  color: #5e6282;
  font-family: "Poppins Medium";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
}
.decorContainer {
  display: inline-block;
}
.decorContainer img {
  width: 15.1rem;
  object-fit: contain;
}
.appStoreContainer {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.appStoreContainer img {
  width: 13rem;
  height: 4rem;
  object-fit: contain;
  cursor: pointer;
}
.appStoreContainer a:nth-child(1) {
  margin-right: 1rem;
}
/* ----------------------------------------------for width upto 1100px */
@media screen and (min-width: 1100px) {
  .slideImage {
    object-position: 0% 100%;
  }
}
/* ----------------------------------------------for width upto 1100px */
@media screen and (max-width: 1100px) {
  .carouselContainer {
    height: 85vh;
  }
  .slideImage {
    object-position: 0% 100%;
  }
}
/* ----------------------------------------------for width upto 1000px */
@media screen and (max-width: 1000px) {
  .carouselContainer {
    height: 75vh;
  }
}
/* ----------------------------------------------for width upto 900px */
@media screen and (max-width: 900px) {
  .slideImage {
    width: 105%;
  }
}
/* ----------------------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .adContent h2 {
    font-size: 1.5rem;
  }
  .priceContainer h1 {
    font-size: 4.9rem;
    line-height: 6.1rem;
  }
  .adContent {
    height: 100%;
    width: 50%;
  }
  .slideContainer {
    width: 50%;
  }
  .slideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.4);
    transform-origin: 0% 40%;
    opacity: 0.6;
    z-index: -1;
    object-position: 0% 100%;
  }
  .carouselContainer {
    background: none;
  }
  .descriptionLine {
    display: none;
  }
  .appStoreContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
  }
  .appStoreContainer img {
    width: 18rem;
    height: 98%;
  }
  .appStoreContainer a:nth-child(1) {
    margin-bottom: 1rem;
  }
  .decorContainer img {
    width: 14rem;
  }
}
/* ----------------------------------------------for width upto 600px */
@media screen and (max-width: 600px) {
  .carouselContainer {
    height: 70vh;
  }
  .adContent h2 {
    font-size: 1.7rem;
  }
  .priceContainer h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
  .appStoreContainer {
    margin-top: 2rem;
  }
  .appStoreContainer img {
    width: 16rem;
    height: 85%;
  }
  .decorContainer img {
    width: 12.5rem;
  }
}
/* ----------------------------------------------for width upto 500px */
@media screen and (max-width: 500px) {
  .slideImage {
    width: 130%;
  }
  .adContent h2 {
    font-size: 1.5rem;
  }
  .priceContainer h1 {
    font-size: 3.7rem;
    line-height: 4.8rem;
  }
  .appStoreContainer {
    margin-top: 2rem;
  }
  .decorContainer img {
    width: 11rem;
  }
  .adContent {
    height: 100%;
    width: 60%;
  }
  .slideContainer {
    width: 40%;
  }
}
/* ----------------------------------------------for width upto 768px */
@media screen and (max-width: 400px) {
  .carouselContainer {
    height: 60vh;
  }
  .slideImage {
    width: 130%;
  }
  .adContent {
    height: 100%;
    width: 62%;
  }
  .slideContainer {
    width: 38%;
  }
  .decorContainer img {
    width: 10rem;
  }
}
/* ----------------------------------------------for width upto 300px */
@media screen and (max-width: 300px) {
  .carouselContainer {
    height: 55vh;
  }
  .adContent h2 {
    font-size: 1.4rem;
  }
  .priceContainer h1 {
    font-size: 3.4rem;
    line-height: 4.5rem;
  }
  .decorContainer img {
    width: 8rem;
  }
}
