.productContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 28px;
  background: radial-gradient(
    67.51% 39.83% at 86.42% 77.74%,
    #ff72ef 0%,
    #fbddf7 100%
  );
  padding: 1rem 2.5rem;
  width: 55%;
  position: relative;
  backdrop-filter: blur(100px);
  z-index: 0;
}
.productOuterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10rem 0rem;
}
.saleInfo {
  width: 60%;
}
.saleInfo h4 {
  font-family: "Judson Regular";
  font-weight: 400;
  font-size: 2rem;
  line-height: 4.4rem;
  color: #111827;
}
.saleInfo h3 {
  font-family: "Avenir Regular";
  font-weight: bold;
  font-size: 4.2rem;
  line-height: 3rem;
  color: #111827;
}
.saleInfo p {
  margin-top: 1.5rem;
  font-family: "Avenir Regular";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #9e69aa;
}
.exploreButton {
  background-image: linear-gradient(to right, #af51c5, #6e297e);
  box-shadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.25)";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  border-radius: 12px;
  margin-top: 2rem;
  padding: 0.5rem 0rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.exploreStore {
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: white;
}
.exploreButton:hover {
  background-image: none;
  background-color: white;
  color: #9132a2;
}
.exploreButton:hover .exploreStore {
  color: #9132a2;
}
.productView {
  position: relative;
  height: 20rem;
  display: inline-block;
  display: flex;
  justify-content: center;
  width: 40%;
}
.productView img {
  height: 30rem;
  object-fit: contain;
  position: absolute;
  top: -9rem;
}
/* ----------------------------------for width upto 768px */
@media screen and (max-width: 768px) {
  .productContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    margin-top: 8rem;
  }
  .saleInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .productView {
    height: 10rem;
    width: 100%;
  }
  .productView img {
    height: 30rem;
    top: -18rem;
  }
  .saleInfo h4 {
    font-size: 3.8rem;
    line-height: 5rem;
  }
  .saleInfo h3 {
    font-size: 4.8rem;
    line-height: 5rem;
  }
  .saleInfo p {
    width: 60%;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
  }
  .exploreButton {
    margin-top: 2rem;
    padding: 2rem 1.5rem;
  }
}
/* ----------------------------------for width upto 600px */
@media screen and (max-width: 600px) {
  .productContainer {
    width: 65%;
    padding: 3rem 2rem;
    margin-top: 8rem;
  }
  .saleInfo h4 {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
  .saleInfo h3 {
    font-size: 4.2rem;
    line-height: 4.4rem;
  }
  .saleInfo p {
    width: 90%;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  .exploreButton {
    margin-top: 2rem;
    padding: 2rem 1.5rem;
  }
}
/* ----------------------------------for width upto 500px */
@media screen and (max-width: 500px) {
  .productContainer {
    width: 70%;
    padding: 3rem 2rem;
    margin-top: 8rem;
  }
  .saleInfo p {
    width: 95%;
  }
  .exploreButton {
    padding: 1.5rem 1.5rem;
  }
}
/* ----------------------------------for width upto 300px */
@media screen and (max-width: 300px) {
  .productContainer {
    width: 85%;
    padding: 3rem 2rem;
    margin-top: 8rem;
  }
}
